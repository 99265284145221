import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import "./TerminalDisplay.scss";
import { TerminalText } from "./TerminalText";

/**
 * @param {object} props 
 * @param {Uint8Array[]} props.text The data to display in the terminal
 * @param {bool} props.autoScroll Indicates if the terminal should auto scroll when text is added
 * @param {function} props.onScroll Called when the terminal display is scrolled
 */
export function TerminalDisplay({ text, autoScroll, onScroll })
{
    const displayRef = useRef();

    useLayoutEffect(() =>
    {
        if (!autoScroll)
            return;

        displayRef.current.scrollTop = displayRef.current.scrollHeight;
    }, [text]);


    return (<div styleName="terminal-display" onScroll={onScroll} ref={displayRef}>
        {
            text.map((textChunk) =>
            {
                return <TerminalText key={textChunk.key} text={textChunk.text} rendition={textChunk.rendition} />
            })
        }
    </div>);
}