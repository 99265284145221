import classNames from "classnames";
import "./Notification.scss";

export function Notification({ title, popover = true, children })
{
    const notification = (<div styleName={classNames("notification")}>
        {title && title.length && <label styleName="title">{title}</label>}
        <div styleName="message">
            {children}
        </div>
    </div>);

    if (!popover)
        return notification;

    return (<div styleName="notification-popover-overlay">
        {notification}
    </div>);
}