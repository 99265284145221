import "./SquareFrame.scss";
import classNames from "classnames";

export function SquareFrame({ size, className, styleName, style, children })
{
    let sizeClass;

    if (size === "small")
        sizeClass = "small";
    else if (size === undefined || size === "medium")
        sizeClass = "medium";
    else if (size === "large")
        sizeClass = "large";

    return (<div className={className} styleName={classNames("square-frame", sizeClass, styleName)} style={style}>{children}</div>);
}