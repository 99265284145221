import { createSlice } from '@reduxjs/toolkit'

const InitialAccountState = {
    /**
     * The logged in account
     * @type {string}
     */
    account: undefined,

    /**
     * Indicates if the login page should be shown
     */
    showLoginPage: false,

    /**
     * A message to display when the login failed
     * @type {string}
     */
    failureMessage: null,

    /**
     * Indicates if an active session to the MUD was disconnected
     */
    disconnected: false
}

function displayLoginPageReducer(state, action)
{
    return { ...state, failureMessage: null, showLoginPage: true, ...action.payload };
}

function hideLoginPageReducer(state, action)
{
    return { ...state, showLoginPage: false };
}

function setDisconnectedReducer(state, action)
{
    return { ...state, disconnected: action.payload };
}

const accountSlice = createSlice({
    name: "account",
    reducers: { displayLoginPage: displayLoginPageReducer, hideLoginPage: hideLoginPageReducer, setDisconnected: setDisconnectedReducer },
    initialState: { ...InitialAccountState }
})

export const { displayLoginPage, hideLoginPage, setDisconnected } = accountSlice.actions;

export const accountReducer = accountSlice.reducer; 
