import React, { createContext } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux'
import { startClient } from "./services/communication";
import state from "./state";
import { App } from "./App";
import { ClientContext } from "./services";

const webSocketProtocol = window.location.protocol === "https:" ? "wss:" : "ws:";
const webSocketHostname = "www.riseofpraxis.net";

const connectionSettings = { url: `${webSocketProtocol}//${webSocketHostname}` };

const client = startClient(connectionSettings);

ReactDOM.createRoot(document.getElementById("web-client")).render(
    <ClientContext.Provider value={client}>
        <Provider store={state}>
            <App/>
        </Provider>
    </ClientContext.Provider>
);