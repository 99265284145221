import { RoundFrame, SquareFrame } from "../../components";
import "./Item.scss";

export function Item({ iconSrc, name, conditionPct, group })
{    
    const count = (group && group.length) || 1;

    return (<div styleName="item">
        <SquareFrame size="small">
            <img src={iconSrc} />
        </SquareFrame>
        <label>{name}</label>
        {count > 1 && <RoundFrame size="small" styleName="item-count">{count}</RoundFrame>}
    </div>);
}