export * from "./GMCPState";
export * from "./TerminalState"
export * from "./AccountState";
import { terminalReducer } from "./TerminalState";
import { roomReducer, charReducer } from "./GMCPState";
import { accountReducer } from "./AccountState";

import { configureStore } from '@reduxjs/toolkit'

export default configureStore({
    reducer: {
        account: accountReducer,
        terminal: terminalReducer,
        room: roomReducer,
        char: charReducer
    }
});