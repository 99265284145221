import EventEmitter from "events";

export class AnsiConstants
{
    static ESC = 27;
    static CSI = 91;
    static ST = 92;
    static OSC = 93;
    static SGRFunction = 109;
}

export class TelnetOptions
{
    static Echo = 1;
    static TerminalType = 24;
    static WindowSize = 31;
    static Environment = 39;
    static CharSet = 42;

    // MUD Specific
    static MCCP = 86;       // MUD Client Compression Protocol
    static MSDP = 69;       // MUD Server Data Protocol
    static MSSP = 70;       // MUD Server Status Protocol
    static MSP = 90;        // MUD Sound Protocol
    static MXP = 91;        // MUD Extentions Protocol

    static GMCP = 201;
}

export class TelnetConstants
{
    static EOR = 239;
    static SE = 240;
    static NOP = 241;
    static GoAhead = 249;
    static SB = 250;
    static WILL = 251;
    static WONT = 252;
    static DO = 253;
    static DONT = 254;
    static IAC = 255;
}

export const _gmcpRegEx = /(?<command>[^\s]+)(\s(?<data>.+))?/i;
const _textEncoder = new TextEncoder();
const _textDecoder = new TextDecoder();

/**
 * The client used to connect to the MUD
 */
export class MUDClient extends EventEmitter
{
    /**
     * @type {WebSocket}
     */
    #connection;

    /**
     * The buffer of data that hasn't been processed yet
     * @type {Uint8Array}
     */
    #buffer = null;

    /**
     * Indicates if GMCP enabled
     */
    gmcpEnabled = true;

    /**
     * Indicates if the MUD Client is connected
     * @type {bool}
     */
    get connected() { return this.#connected; }
    #connected = false;

    /**
     * Called when basic text was received
     * @param {Uint8Array} text The text that was received
     */
    #onTextMessage(text)
    {
        this.emit("text", Array.from(text));
    }

    /**
     * Called when a telnet negotiation request is received
     */
    handleTelnetOptionsNegotiation(request, option)
    {
        switch (option)
        {
            case TelnetOptions.TerminalType:
            case TelnetOptions.WindowSize:
            case TelnetOptions.Environment:
            case TelnetOptions.MCCP:
            case TelnetOptions.MSDP:
            case TelnetOptions.MSSP:
            case TelnetOptions.MSP:
            case TelnetOptions.CharSet:
                // We're not supporting these features
                if (request === TelnetConstants.DO)
                    this.send([TelnetConstants.IAC, TelnetConstants.WONT, option]);
                else if (request === TelnetConstants.WILL)
                    this.send([[TelnetConstants.IAC, TelnetConstants.DONT, option]])

                console.warn(`Declining to support telnet option request [${option}]`);
                break;
            case TelnetOptions.Echo:
                this.emit("mask-input", option === 1);
                break;

            case TelnetOptions.GMCP:
                this.gmcpEnabled = request !== TelnetConstants.WONT;
                break;

            default:
                console.warn(`Received telnet option request of [${option}] but didn't handle it`);
        }
    }

    /**
     * Processes GMCP data that is received
     * @param {Uint8Array} message The GMCP message received
     * @emits gmcp
     */
    #handleGMCP(message)
    {
        if (!this.gmcpEnabled)
            return;

        // Parse the GMCP message
        const match = _gmcpRegEx.exec(_textDecoder.decode(message));
        const { groups } = match;
        const command = groups.command;
        const data = groups.data ? JSON.parse(groups.data) : undefined;

        this.onGMCPMessage(command, data);
    }

    /**
     * Called when a telnet subnegotiation is received
     * @param {number} option The negotiation option
     * @param {Uint8Array} data The data associated the sub negotiation sequence
     */
    handleTelnetOptionSubnegotiation(option, data)
    {
        if (option === TelnetOptions.GMCP)
            return this.#handleGMCP(data);
    }


    /**
     * Called when a telnet command was received
     * @param {Uint8Array} commandSequence The text that was received
     */
    #onTelnetCommad(commandSequence)
    {
        const telnetCommand = commandSequence[1];

        if ((telnetCommand === TelnetConstants.WILL
            || telnetCommand === TelnetConstants.WONT
            || telnetCommand === TelnetConstants.DO
            || telnetCommand === TelnetConstants.DONT))
        {
            this.handleTelnetOptionsNegotiation(telnetCommand, commandSequence[2]);
            return;
        }

        if (telnetCommand == TelnetConstants.GoAhead)
        {
            return;
        }

        if (telnetCommand === TelnetConstants.SB)
        {
            const option = commandSequence[2];
            const data = commandSequence.subarray(3, commandSequence.length - 2)

            this.handleTelnetOptionSubnegotiation(option, data);
            return;
        }

        if (telnetCommand === TelnetConstants.GoAhead)
            return;

        debugger;

    }

    /**
     * Returns the next telnet command in the data
     * @param {Uint8Array} data The data to pull the telnet command from
     * @param {number} [startIndex = 0]
     */
    #getTelnetCommand(data, startIndex = 0)
    {
        let endIndex = startIndex + 1;
        const telnetCommand = data[endIndex];

        if ((telnetCommand === TelnetConstants.WILL
            || telnetCommand === TelnetConstants.WONT
            || telnetCommand === TelnetConstants.DO
            || telnetCommand === TelnetConstants.DONT))
        {
            // Grab the next three bytes in the stream
            if (data.length > 2)
                return data.subarray(startIndex, startIndex + 3);

            return null;
        }

        // If this is a sub-negotiation sequence, read the set of options
        if (telnetCommand === TelnetConstants.SB)
        {
            while (endIndex < data.length)
            {
                if (data[endIndex] === TelnetConstants.IAC
                    && data[endIndex + 1] === TelnetConstants.SE)
                    return data.subarray(startIndex, endIndex + 2);

                endIndex++;
            }

            return null;
        }

        // If the sequece is IAC IAC, then this is a means of escaping the IAC value (255)
        if (telnetCommand === TelnetConstants.IAC
            || telnetCommand === TelnetConstants.GoAhead)
            return data.subarray(startIndex, endIndex + 1);


        return null;
    }

    /**
     * Returns the next message from a data stream
     * @param {Uint8Array} data The data to pull the next message from
     * @returns {Uint8Array}
     */
    #getNextMessage(data)
    {
        let endIndex = 0;

        if (!data || !data.length)
            return [];

        while (endIndex < data.length)
        {
            if (data[endIndex] == TelnetConstants.IAC)
            {
                // If the chunk starts with IAC, then return the telnet command 
                if (endIndex === 0)
                    return this.#getTelnetCommand(data);

                // There was data already so return that instead
                break;
            }

            endIndex++;
        }

        if (endIndex > 0)
            return data.subarray(0, endIndex);

        return data;
    }

    /**
     * Parses the data and breaks it down into multiple sections based on the content
     * @param {Uint8Array} data The data to be processed
     * @returns {Uint8Array[]}
     */
    #getMessages(data)
    {
        const messages = [];
        let startIndex = 0;

        if (!data || !data.length)
            return messages;

        while (startIndex < data.length)
        {
            const nextMessage = this.#getNextMessage(data.subarray(startIndex));
            if (!nextMessage)
                break;

            messages.push(nextMessage);
            startIndex += nextMessage.length;
        }

        return messages;
    }

    onGMCPMessage(command, data)
    {
        this.emit("gmcp", { command, data });
    }

    /**
     * Called when there is data received from the connection
     * @param {Blob | string | Uint8Array} data The data received to be processed
     */
    onMessage(data)
    {
        if (data instanceof Uint8Array)
        {
            let buffer = data;
            if (this.#buffer)
            {
                // Merge the data with any previous buffer
                buffer = new Uint8Array(this.#buffer.length + data.length);
                buffer.set(this.#buffer);
                buffer.set(data, this.#buffer.length);
            }

            const messages = this.#getMessages(buffer);

            let totalSize = 0;
            for (const message of messages)
            {
                if (message[0] !== TelnetConstants.IAC)
                {
                    this.#onTextMessage(message);
                }
                else
                {
                    this.#onTelnetCommad(message);
                }

                totalSize += message.length;
            }

            if (totalSize !== buffer.length)
            {
                // Pull out data that wasn't processed so that it can be
                // appended to by any up-coming data
                this.#buffer = buffer.subarray(totalSize);
            }
            else
            {
                this.#buffer = null;
            }
            return;
        }

        // If the data is a string, convert it to Uint8Array
        if (typeof (data) === "string")
        {
            const encoder = new TextEncoder();
            this.onMessage(encoder.encode(data));
            return;
        }

        // If the data is a Blob, wait until it's fetched and then reprocess
        if (data instanceof Blob)
        {
            data
                .arrayBuffer()
                .then((value) =>
                {
                    this.onMessage(new Uint8Array(value));
                });

            return;
        }

        if (data instanceof ArrayBuffer)
            return this.onMessage(new Uint8Array(data));

        console.warn(`MUDClient.onMessage received invalid data type: ${typeof (data)}`)
    }

    /**
     * Connects to the server
     * @param {object} options
     * @param {string} options.url The server's web socket url to connect to
     */
    connect({ url })
    {
        const webSocket = new WebSocket(url);
        webSocket.onmessage = (event) =>
        {
            this.onMessage(event.data);
        };
        webSocket.onerror = (event) => { this.emit("error", event); }
        webSocket.onopen = (event) =>
        {
            this.#connection = webSocket;
            webSocket.binaryType = "arraybuffer";

            // Negotiate with the server regarding supported features
            this.send([TelnetConstants.IAC, TelnetConstants.DO, TelnetOptions.GMCP]);

            this.#connected = true;
            this.emit("connected");
        }
        webSocket.onclose = (event) =>
        {
            this.#connection = null;
            this.#connected = false;
            this.emit("closed");
        }
    }

    /**
     * Sends GMCP message to the MUD
     * @param {string} command The GMCP command
     * @param {object} [data] The data associated with the GMCP command
     */
    sendGMCP(command, data)
    {
        const message = [TelnetConstants.IAC, TelnetConstants.SB, TelnetOptions.GMCP];
        let payload = command;

        if (data)
            payload += " " + JSON.stringify(data);

        message.push(..._textEncoder.encode(payload));
        message.push(TelnetConstants.IAC, TelnetConstants.SE);

        this.send(message);
    }

    /**
     * Sends data to the server
     * @param {string | Uint8Array} data The data to send to the server
     */
    send(data)
    {
        if (!this.#connection)
        {
            console.warn("MUDClient is not connected.");
            return;
        }

        const connection = this.#connection;
        if (typeof (data) === "string")
            connection.send(`${data}\n`);
        else
        {
            if (data instanceof Uint8Array)
            {
                connection.send(data);
                return;
            }

            this.send(new Uint8Array(data));

        }
    }
}