import React, { useCallback, useContext, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from 'react-redux'
import { TerminalDisplay, Player, TerminalInput, Room } from "../../features";
import { echoText } from "../../state";
import { Frame } from "../../components";
import { MUDClient } from "../../services/MUDClient";
import { ClientContext } from "../../services";
import "./HUD.scss";
import "./Terminal.scss";

/**
 * The main interface for Rise of Praxis web client
 * @param {Object} props 
 * @param {MUDClient} props.client The client used to communicate with the MUD 
 */
export function HUD({ className })
{
    const client = useContext(ClientContext);
    const [terminalInputType, setTerminalInputType] = useState("text");
    const primaryTerminalState = useSelector((state) => state.terminal);
    const roomState = useSelector((state) => state.room);
    const characterState = useSelector((state) => state.char);
    const [autoScroll, setAutoScroll] = useState(true);

    const dispatch = useDispatch();

    const onSendCallback = useCallback((message, hidden) =>
    {

        if (!hidden)
            dispatch(echoText(message));
        client.send(message);

        setAutoScroll(true);
        setTerminalInputType("text");
    }, [client]);

    const onScrollCallback = useCallback((event) =>
    {
        const display = event.target;

        const { scrollHeight, scrollTop, clientHeight } = display;
        const scrollFromBottom = scrollHeight - (scrollTop + clientHeight);

        setAutoScroll(scrollFromBottom < 30);
    }, []);

    const onMaskInputCallback = useCallback((maskInput) =>
    {
        setTerminalInputType(maskInput ? "password" : "text");
    }, [client]);

    React.useEffect(() =>
    {
        client.on("mask-input", onMaskInputCallback);

        return () =>
        {
            client.off("mask-input", onMaskInputCallback);
        }
    }, [client]);

    return (<React.Fragment>
        <div className={className} styleName="hud">
            <aside styleName={classNames({ "character-sidebar": roomState != null })}></aside>
            <section>
                <Frame styleName="terminal primary-terminal">
                    <TerminalDisplay text={primaryTerminalState.text} autoScroll={autoScroll} onScroll={onScrollCallback} />
                </Frame>
                {client.connected && <TerminalInput placeholder="Enter text here" onSend={onSendCallback} autoFocus="on" type={terminalInputType} />}
                <Player {...characterState} />
            </section>
            <aside styleName={classNames({ "room-sidebar": roomState != null })}>
                {roomState && <Room {...roomState} client={client} />}
            </aside>
        </div>
    </React.Fragment>);
}