import { getCharacterCondition } from "../../functions";
import { Character } from "../Character/Character";
import "./CharacterList.scss";

/**
 * Provides basic functionality of displaying a set of characters
 * @param {object} props
 * @param {import("../Character/Character").CharacterModel[]} props.characters The characters to generate the list for
 * @param {function} [props.onAction] A callback when a unit action button is clicked. 
 */
export function CharacterList({ characters, onAction })
{
    if (!characters)
        return null;

    // Group all the units by name + health level
    /** @type {Map<string, import("../Character/Character").CharacterModel[]} */
    const groups = new Map();

    const sorted = [...characters];
    sorted.sort((a, b) =>
    {
        if (a.type === b.type)
            return a.type.localeCompare(b.type);

        if (a.role === "creator")
            return -1;
        else if (b.role === "creator")
            return 1;
        else if (a.type === "player")
            return -1;
        else if (b.type === "player")
            return 1;
    });

    sorted.forEach((character) =>
    {
        const name = character.name.toLocaleLowerCase();
        const condition = getCharacterCondition(character);
        const key = `${name}-${condition}`;
        if (!groups.has(key))
            groups.set(key, { ...character, group: [] });
        const unitGroup = groups.get(key);
        unitGroup.group.push(character);
    }, {});

    // loop through all the groups
    const frames = [];
    groups.forEach((character) =>
    {
        frames.push(<Character key={character.id} {...character} onAction={onAction} />);
    });

    return <div styleName="character-list">{frames}</div>;
}