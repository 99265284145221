import state, { appendText, setDisconnected, applyCharGmcpCommand, applyRoomGmcpCommand, displayLoginPage, hideLoginPage } from '../state'
import { MUDClient } from "../services";
import { MockMUDClient } from "../../__mocks__/MUDClient/MockMUDClient";

const _gmcpCharCommand = /^RoP\.Char(\.(?<module>.+))?$/i;
const _gmcpRoomCommand = /^RoP\.Room(\.(?<module>.+))?$/i;

const onReceivedTextCallback = (text) =>
{
    state.dispatch(appendText(text));
};

const onReceivedGMCPCallback = ({ command, data }) =>
{
    switch (command.toLowerCase())
    {
        case "char.login.default":
            // Trigger the login page
            state.dispatch(displayLoginPage(data))
            break;

        case "char.login.result":
            if (data.success)
            {
                state.dispatch(hideLoginPage());
            }
            else
            {
                state.dispatch(displayLoginPage({ failureMessage: data.message }));
            }
            break;

        default:
            let match = _gmcpCharCommand.exec(command);
            if (match)
                return state.dispatch(applyCharGmcpCommand({ module: match.groups.module || "set", data }));

            match = _gmcpRoomCommand.exec(command);
            if (match)
                return state.dispatch(applyRoomGmcpCommand({ module: match.groups.module || "set", data }));
    }
};

const onCloseCallback = () =>
{
    state.dispatch(appendText("~~Disconnected~~"));
    state.dispatch(hideLoginPage());
    state.dispatch(setDisconnected(true));
};

const onConnectedCallback = () =>
{
    state.dispatch(setDisconnected(false));
};

export function startClient(connectionSettings)
{
    const client = new MUDClient();
    // const client = new MockMUDClient()
    client.connect(connectionSettings);

    client.on("text", onReceivedTextCallback);
    client.on("gmcp", onReceivedGMCPCallback);
    client.on("closed", onCloseCallback);
    client.on("connected", onConnectedCallback);

    return client;
}