import { useSelector } from "react-redux";
import "./App.scss";
import { HUD, Login } from "./pages";
import { Notifications } from "./features";

export function App()
{
    const account = useSelector((state) => state.account);
    const showLoginPage = useSelector((state) => state.account.showLoginPage);
    let page = null;

    if (showLoginPage)
        page = <Login />;
    else
        page = <HUD />;

    return <React.Fragment>
        {page}
        {account.disconnected && <Notifications.Disconnected />}
    </React.Fragment>
}