import { Header } from "../../components";
import { CharacterList } from "../CharacterList/CharacterList";
import { ItemList } from "../ItemList/ItemList";

const occupantTypes = ["npc", "player", "immortal"];

export function Room({ name, inventory = [], client })
{
    if (!name)
        return undefined;

    const occupants = inventory.filter((element) => occupantTypes.indexOf(element.type) !== -1);
    const items = inventory.filter((element) => occupantTypes.indexOf(element.type) === -1);

    return (<React.Fragment>
        <Header>{name}</Header>
        <CharacterList characters={occupants} onAction={({ command }) => { client.send(command) }} />
        {/* <ItemList items={items} /> */}
    </React.Fragment>);
}