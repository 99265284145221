import "./CrystalButton.scss";

export function CrystalButton(props)
{
    const buttonProps =
    {
        ...props,
        type: "button"
    };

    return (<input styleName="crystal-button" {...buttonProps} />);
}