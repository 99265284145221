import { createSlice } from '@reduxjs/toolkit'

/**
 * @typedef {object} TerminalText
 * @property {number} key A unique key for the line
 * @property {object} rendition How the text should be rendered
 * @property {string} text The text for the line
 */

/**
 * @typedef {object} TerminalState
 * @property {TerminalText[]} text The text for the terminal
 * @property {number} maxLines The maximum number of lines to keep in the history
 */

/**
 * @type {TerminalState}
 */
export const InitialTerminalState = {
    text: [],
    maxLines: 1000
}
const _defaultRendition =
{
    bold: false,
    underline: false,
    italics: false,
    color: 38,
    backgroundColor: 48
}

const _textDecoder = new TextDecoder();
const _lineRegEx = new RegExp(/(.*[\r\n|\r|\n])/gi);

const ESCAPE = 27;
const CR = 13;
const LF = 10;
const CSI = "[".charCodeAt(0);
const SGRFunction = "m".charCodeAt(0);

export class SGRAttribute
{
    static Reset = 0;
    static Bold = 1;
    static Italics = 2;
    static Underline = 4;
    static ForegroundBlack = 30;
    static ForegroundRed = 31;
    static ForegroundGreen = 32;
    static ForegroundYellow = 33;
    static ForegroundBlue = 34;
    static ForegroundMagenta = 35;
    static ForegroundCyan = 36;
    static ForegroundWhite = 37;
    static Foreground = 38;
    static DefaultForeground = 39;
    static BackgroundBlack = 40;
    static BackgroundRed = 41;
    static BackgroundGreen = 42;
    static BackgroundYellow = 43;
    static BackgroundBlue = 44;
    static BackgroundMagenta = 45;
    static BackgroundCyan = 46;
    static BackgroundWhite = 47;
    static Background = 48;
    static DefaultBackground = 49;
    static ForegroundBrightBlack = 90;
    static ForegroundBrightRed = 91;
    static ForegroundBrightGreen = 92;
    static ForegroundBrightYellow = 93;
    static ForegroundBrightBlue = 94;
    static ForegroundBrightMagenta = 95;
    static ForegroundBrightCyan = 96;
    static ForegroundBrightWhite = 97;
}

let _lineIndex = 0;

/**
 * @param {TerminalText} [previousText] The previous text state
 * @returns {TerminalText}
 */
function createNewText(previousText)
{
    if (_lineIndex === Number.MAX_SAFE_INTEGER)
        _lineIndex = 0;

    return {
        key: _lineIndex++,
        rendition: (previousText ? { ...previousText.rendition } : { ..._defaultRendition }),
        text: "",
        endOfLine: false
    }
}

function getEscapeSequence(data)
{
    // Extract the segment of the data containing the escape code, including the leading escape character
    if (!data || !data.length)
        return data;

    const escapeCode = data[1];
    let endIndex = 2;
    switch (escapeCode)
    {
        case CSI:
            // Find the next letter character
            while (endIndex < data.length)
            {
                const char = data[endIndex];
                if (char >= 65 && char <= 122)
                {
                    endIndex++;
                    break;
                }
                endIndex++;
            }

            return data.subarray(0, endIndex);
    }
}

/**
 * Returns the immediate sequence of data that is text
 * @param {Uint8Array} data The data to remove the text from
 */
function getText(data)
{
    let endIndex = 0;

    while (endIndex < data.length)
    {
        const currentChar = data[endIndex];
        if (currentChar === CR
            || currentChar === LF)
        {
            endIndex++;

            // Return the subarray to also include the newline
            if (data[endIndex] === CR
                && data[endIndex + 1] === LF)
                endIndex++;

            break;
        }

        if (currentChar === ESCAPE)
            break;

        endIndex++;
    }

    return data.subarray(0, endIndex);
}

/**
 * @param {Uint8Array} data The data to pull the next chunk from, starting at the beginning
 * @returns {Uint8Array}
 */
function getNextText(data)
{
    if (!data.length)
        return data;

    if (data[0] === ESCAPE)
        return getEscapeSequence(data);


    return getText(data);
}

function applySGR(previousRendition, args)
{
    const rendition = { ...previousRendition };

    const n = args.length ? args[0] : 0;

    switch (n)
    {
        case SGRAttribute.Reset: return { ..._defaultRendition };
        case SGRAttribute.Bold: rendition.bold = true; break;
        case SGRAttribute.Italics: rendition.italics = true; break;
        case SGRAttribute.Underline: rendition.underline = true; break;
        case SGRAttribute.DefaultForeground: rendition.color = _defaultRendition.color; break;
        case SGRAttribute.Foreground:
            if (args.length < 2)
                rendition.color = _defaultRendition.color;
            break;

        case SGRAttribute.DefaultBackground: rendition.backgroundColor = _defaultRendition.backgroundColor; break;
        case SGRAttribute.Background:
            if (args.length < 2)
                rendition.backgroundColor = _defaultRendition.backgroundColor;
            break;

        default:
            if ((n >= 30 && n <= 37) || (n >= 90 && n <= 97))
                rendition.color = n;
            else if ((n >= 40 && n <= 47) || (n >= 100 && n <= 107))
                rendition.backgroundColor = n;
            else
            {
                debugger;
            }
    }

    return rendition;
}

/**
 * @param {Uint8Array} data The Control Sequence Introducer data
 */
function getCSIArguments(data)
{
    // Convert the data into string and split it by ;
    const args = _textDecoder.decode(data.subarray(2, data.length - 1)).split(";");

    return args.map((arg) =>
    {
        return !arg.length ? 0 : parseInt(arg);
    });
}

/**
 * Indicates if two terminal stylings are the same or not
 */
function isStylingSame(a, b)
{
    for (const key in a)
    {
        if (a[key] !== b[key])
            return false;
    }

    return true;
}

/**
 * Creates the ANSI Escape sequence for the and SGR function
 * @param {number[]} args The arguments to pass with the SGR function 
 */
export function createAnsiSGRSequence(...args)
{
    let params = "0";

    if (args.length)
        params = args.join(";");

    return `\u001b[${params}m`;
}

/**
 * Applies rendition updates to the latest entry in a list of text entries
 * @param {TerminalText[]} entries The complete set of entries
 * @param {Uint8Array} text The data that was sent
 */
function applyRenditionUpdate(entries, text)
{
    let lastEntry = entries[entries.length - 1];
    let rendition = lastEntry.rendition;

    // Extract the sequence parameters
    const args = getCSIArguments(text);
    const csiFunction = text[text.length - 1];
    if (csiFunction === SGRFunction)
        rendition = applySGR(rendition, args);

    // Start a new block of text if there is already text in the current one
    if (lastEntry.text.length
        || !isStylingSame(rendition, lastEntry.rendition))
    {
        lastEntry = { ...createNewText(lastEntry), rendition: rendition };
        entries.push(lastEntry);
    }
}

/**
 * Applies text updates to the latest entry in a list of text entries
 * @param {TerminalText[]} entries The complete set of entries
 * @param {string} newText The text data to add
 */
function applyTextUpdate(entries, newText)
{
    let lastEntry;

    // Split the text into multiple lines
    lastEntry = { ...entries[entries.length - 1] };

    if (!lastEntry.text.length)
    {
        lastEntry.text = newText;
        entries[entries.length - 1] = lastEntry;
    }
    else
    {
        const newEntry = createNewText(lastEntry);
        newEntry.text = newText;
        entries.push(newEntry);
    }
}

/**
 * Appends text to the current state
 * @param {TerminalState} state The terminal state to update
 * @param {object} action The reducer action
 */
function appendTextReducer(state, action)
{
    let textEntries = state.text.slice();
    let payload = action.payload;
    if (typeof (payload) === "string")
        payload = new TextEncoder().encode(payload);

    const data = new Uint8Array(payload);
    let startIndex = 0;

    if (!data.length)
        return;

    while (startIndex < data.length)
    {
        const text = getNextText(data.subarray(startIndex));
        if (!text || !text.length)
        {
            console.warn(`Did not extract any data starting at ${startIndex}: "...${_textDecoder.decode(data.subarray(startIndex, startIndex + 10))}..."`)
            break;
        }

        startIndex += text.length;

        // Determine what to do with the chunk of data
        if (!textEntries.length)
            textEntries.push(createNewText());

        if (text[0] === ESCAPE)
        {
            if (text[1] === CSI)
                applyRenditionUpdate(textEntries, text);
        }
        else
        {
            // The data is just plain text.  Append it to the list
            applyTextUpdate(textEntries, _textDecoder.decode(text));
        }
    }

    // Trim out old lines of text
    let count = 0;
    let start = textEntries.length - 1;
    while (start)
    {
        const entry = textEntries[start];
        if (entry.text.match(_lineRegEx))
            count++;

        if (count > state.maxLines)
            break

        start--;
    }

    if (start)
        textEntries = textEntries.slice(start);

    return { ...state, text: textEntries };
}

function echoTextReducer(state, action)
{
    const message = action.payload;

    let echo = createAnsiSGRSequence(SGRAttribute.Italics);
    echo += createAnsiSGRSequence(SGRAttribute.Bold)
    echo += `${message}\n`;
    echo += createAnsiSGRSequence(SGRAttribute.Reset);

    const appendTextAction = terminalSlice.actions.appendText(echo);
    return terminalSlice.caseReducers.appendText(state, appendTextAction);
}

// Create the slice for the terminal
const terminalSlice = createSlice({
    name: "terminal",
    reducers: { appendText: appendTextReducer, echoText: echoTextReducer },
    initialState: { ...InitialTerminalState }
})

export const { appendText, echoText } = terminalSlice.actions;

export const terminalReducer = terminalSlice.reducer;