import classNames from "classnames";
import "./StatBar.scss";

const smallClipPath = [""]

/**
 * @param {object} props
 * @param {number} props.percentage The percentage of the bar to show
 * @param {string} props.orientation The orientation of the bar: top-left, top-right, bottom-right, bottom-left
 */
export function StatBar({ maxValue, value, label, size, color, style, className, styleName, orientation })
{
    let orientationClass = "bottom-left";
    let sizeClass = undefined;
    let fillWidth = 0;
    const percentage = value / maxValue;

    switch (size)
    {
        case "large": sizeClass = "large"; fillWidth = 527; break;
        case "small": sizeClass = "small"; fillWidth = 132; break;
        case "medium":
        default:
            sizeClass = "medium";
            fillWidth = 264;
    }

    switch (orientation)
    {
        case "top-left": orientationClass = ["top", "left"]; break;
        case "top-right": orientationClass = ["top", "right"]; break;
        case "bottom-right": orientationClass = ["bottom", "right"]; break;
        case "bottom-left":
        default:
            orientationClass = ["bottom", "left"]; break;
    }

    let maskOffsetX = fillWidth - (fillWidth * percentage);

    return <div className={className}  styleName={classNames("stat-bar", sizeClass, orientationClass, styleName)} style={style}>
        <div styleName="stat-border" />
        <div styleName="fill-bar-container">
            <div styleName="fill-bar" style={{ backgroundColor: color, maskPosition: `${maskOffsetX}px 0` }} />
            <label>
                {orientation === "top-left" || orientation === "bottom-left"
                    ? `${value} / ${maxValue} : ${label}`
                    : `${label} : ${value} / ${maxValue}`
                }
            </label>
        </div>

    </div>
}