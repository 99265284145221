import { Notification, Link } from "../../components";

export function Disconnected({ })
{
    return (<Notification title="Disconnected">
        <p>
            The connection to the MUD has been disrupted.
        </p>
        <p>
            Refresh the screen to reconnect or <Link onClick={() => window.location.reload()}>click here</Link>.
        </p>
    </Notification>);
}