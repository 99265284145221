import { useCallback } from "react";

/**
 * Adds support for chaining callback functions.
 * @param {function} callback The callback function
 * @param {function} [next] The function to call after this callback function
 * @returns {function}
 */
export function useChainedCallback(callback, next)
{
    return useCallback((...args) =>
    {
        callback(...args);

        if (next && typeof (next) === "function")
            next(...args);
    }, [callback, next]);
}