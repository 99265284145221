import { getQualityCategory } from "../../functions";
import { Item } from "../Item/Item"
import "./ItemList.scss";

export function ItemList({ items })
{
    if (!items)
        return null;

    const sorted = [...items];

    const itemGroups = new Map();
    sorted.forEach((item) =>
    {
        if (!item || !item.name)
            return;
        
        const key = `${item.name.toLocaleLowerCase()}-${getQualityCategory(item.conditionPct)}`;
        if (!itemGroups.has(key))
            itemGroups.set(key, { ...item, group: [] });
        const itemGroup = itemGroups.get(key);
        itemGroup.group.push(item);
    }, {});

    // loop through all the groups
    const itemFrames = [];
    itemGroups.forEach((item) =>
    {
        itemFrames.push(<Item key={item.id} {...item} />);
    });

    return <div styleName="item-list">{itemFrames}</div>;

}