import classNames from "classnames";
import { Frame } from "../Frame/Frame";
import "./Header.scss"

export function Header({ className, children, ornamental = true, framed = true })
{
    const header = (<h1 className={classNames(className, { className: !framed })} styleName={classNames("header", { ornamental })} role="header">
        {children}
    </h1>);

    return (
        framed
            ?
            <Frame className={className}>
                {header}
            </Frame>
            :
            header
    );
}