import { createSlice } from "@reduxjs/toolkit";

const InitialCharState = {
    id: undefined,
    name: undefined,
    fullName: undefined,
    level: undefined,
    avatar: undefined,

    shields: [],
    stats: {
        health: undefined,
        magic: undefined,
        stamina: undefined,
        guild: undefined
    },

    inventory: [],
}

const InitialRoomState = {
    name: undefined,

    inventory: [],
}

function deepMerge(source, update)
{
    const mergedObject = { ...source };

    for (let key in update)
    {
        // If the property on both the merged object and the update is an object
        // then do a deep merge there
        if (typeof (mergedObject[key]) === "object"
            && typeof (update[key] === "object"))
        {
            mergedObject[key] = deepMerge(source[key], update[key])
            continue;
        }

        mergedObject[key] = update[key];
    }

    return mergedObject;
}

function applyGmcpData(parentContext, contextKey, operation, data)
{
    switch (operation)
    {
        case "set":
            if (typeof (data) === "object" && !Array.isArray(data))
                parentContext[contextKey] = deepMerge(parentContext[contextKey], data);
            else
                parentContext[contextKey] = data;
            break;
        
        case "add":
            if (Array.isArray(parentContext[contextKey]))
            {
                if (Array.isArray(data))
                    parentContext[contextKey].push(...data);
                else
                    parentContext[contextKey].push(data);
            }
            break;
        
        case "remove":
            if (Array.isArray(parentContext[contextKey]))
                parentContext[contextKey] = parentContext[contextKey].filter((element) => element.id !== data);
            break;

        case "update":
            if (Array.isArray(parentContext[contextKey]))
            {
                const elements = parentContext[contextKey];
                const { id } = data;

                // Find the matching element in the array
                const elementIndex = elements.findIndex((element) => element.id === id);
                if (elementIndex !== -1)
                    elements[elementIndex] = data;
            } else if (typeof (data) === "object")
                parentContext[contextKey] = deepMerge(parentContext[contextKey], data);
            else
                parentContext[contextKey] = data;
            break;
    }
}

function gmcpCommandReducer(state, action)
{
    const { module, data } = action.payload;
    const moduleParts = module.split(".");
    let root = { ...state };
    let parentContext = root;
    let context = root;
    let contextKey = undefined;

    while (moduleParts.length)
    {
        // Get the next part of the GMCP package/command
        const module = moduleParts.shift().toLowerCase();

        if (module === "set"
            || module === "add"
            || module === "remove"
            || module === "update")
        {
            if (module === "set"
                && !contextKey)
            {
                root = data;
                break;
            }
            applyGmcpData(parentContext, contextKey, module, data);
            break;
        }

        // Find the appropriate key in the current context.  This code ensure proper casing.
        const keys = Object.keys(context);
        const keyIndex = keys.findIndex((key) => key.localeCompare(module) === 0);
        if (keyIndex === -1)
            break;
        const key = keys[keyIndex];

        if (!moduleParts.length)
        {
            // This is the last key so apply the data
            applyGmcpData(context, key, "set", data);
            break;
        }

        // Set the next context.  Since we will be changing it, clone it
        if (Array.isArray(context[key]))
            context[key] = [...context[key]];
        else
            context[key] = { ...context[key] };
        parentContext = context;
        context = context[key];
        contextKey = key;
    }

    return root;
};

const roomSlice = createSlice({
    name: "room",
    reducers: {
        applyRoomGmcpCommand: gmcpCommandReducer
    },
    initialState: null
});

const charSlice = createSlice({
    name: "char",
    reducers: {
        applyCharGmcpCommand: gmcpCommandReducer
    },
    initialState: null
});

export const { applyRoomGmcpCommand } = roomSlice.actions;
export const roomReducer = roomSlice.reducer;

export const { applyCharGmcpCommand } = charSlice.actions;
export const charReducer = charSlice.reducer;