import classNames from "classnames";
import { Avatar, StatBar, RoundFrame, Indicator } from "../../components";
import "./Player.scss";

/**
 * Visual representation of the current player
 */
export function Player({ id, avatar, name, level, stats, shields })
{
    const opacity = id !== undefined ? 1 : 0;
    const { health, magic, stamina, guild } = { ...stats };

    const shieldIndicators = [];
    
    if (shields)
    {
        shields.forEach((shield, index) =>
        {
            const pct = shield.value / shield.maxValue;
            let level = "rgb(0, 255, 0)";
            if (pct < .20)
                level = "rgb(255, 0, 0)";
            else if (pct < .40)
                level = "rgb(255, 191, 0)";
            else if (pct < .60)
                level = "rgb(255, 255, 0)";

            shieldIndicators.push(<Indicator size="x-small" color={level} key={index} />);
        });

        shieldIndicators.reverse();
    };

    while (shieldIndicators.length < 6)
    {
        shieldIndicators.unshift(<Indicator size="x-small" color={"none"} key={shieldIndicators.length} />);
    }

    function renderStatBar({ color, orientation, ...statValues })
    {
        if (!statValues || !statValues.maxValue)
            return undefined;

        return <StatBar styleName={classNames("stat-bar", orientation.split("-"))} {...statValues} color={color} orientation={orientation} />
    }

    return (<div styleName="player" style={{ opacity }}>
        {renderStatBar({ color: "green", orientation: "top-right", ...health })}
        {renderStatBar({ color: "red", orientation: "top-left", ...magic })}
        {renderStatBar({ color: "blue", orientation: "bottom-right", ...stamina })}
        {renderStatBar({ color: "yellow", orientation: "bottom-left", ...guild })}
        <Avatar styleName="player-avatar" url={avatar}></Avatar>
        <RoundFrame size="small" styleName="level">
            <label>{level}</label>
        </RoundFrame>
        {health && shieldIndicators.length && <div styleName="shields">
            {shieldIndicators}
        </div>
        }
    </div>)

}