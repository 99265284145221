import { useCallback, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "@uidotdev/usehooks"
import { Notification, ModalOverlay, Textbox, CrystalButton, Checkbox, Footer, Link, } from "../../components";
import "./Login.scss";
import { ClientContext } from "../../services";
import { hideLoginPage } from "../../state";

export function Login({ })
{
    const client = useContext(ClientContext);
    const accountState = useSelector((state) => state.account);
    const [rememberMe, setRememberMe] = useLocalStorage(true);
    const [storedAccount, setStoredAccount] = useLocalStorage("");
    const [account, setAccount] = useState(() =>
    {
        if (rememberMe && storedAccount.length)
            return storedAccount;

        return "";
    });
    const [password, setPassword] = useState("");
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const dispatch = useDispatch();

    const onLogin = useCallback((event) =>
    {
        if (rememberMe)
            setStoredAccount(account);

        client.sendGMCP("Char.Login.Credentials", { account, password });
    });

    const onAccountInput = useCallback((event) =>
    {
        setAccount(event.target.value);

        setButtonEnabled(event.target.value.length && password.length);
    });

    const onPasswordInput = useCallback((event) =>
    {
        setPassword(event.target.value);

        setButtonEnabled(event.target.value.length && account.length);
    });

    const onKeyDownCallback = useCallback((event) =>
    {
        if (event.code === "Enter"
            && buttonEnabled)
            onLogin();
    });

    let errorMessage;

    if (accountState.failureMessage)
        errorMessage = (
            <label styleName="error">{accountState.failureMessage || "Error authenticating.  Please try again."}</label>
        );
    
    const onShowTerminal = useCallback((event) =>
    {
        dispatch(hideLoginPage());
    });

    return <ModalOverlay>
        <Notification title="Login">
            <div styleName="login-form">
                <div styleName="row">
                    <label htmlFor="username">Name:</label>
                    <Textbox styleName="input" id="username" name="username" placeholder="Username" autoFocus={!account.length} onInput={onAccountInput} onKeyDown={onKeyDownCallback} value={account} />
                </div>

                <div styleName="row">
                    <label htmlFor="password">Password:</label>
                    <Textbox styleName="input" id="password" name="password" placeholder="Password" autoFocus={account.length} type="password" onInput={onPasswordInput} onKeyDown={onKeyDownCallback} value={password} />
                </div>

                <div styleName="row">
                    <label htmlFor="remember-me">Remember me:</label>
                    <div styleName="input">
                        <Checkbox id="remember-me" name="remember-me" onChange={(event) => setRememberMe(event.target.checked)} checked={rememberMe} />
                    </div>
                </div>

                <div styleName="row message">
                    {errorMessage}
                </div>

                <div styleName="row">
                    <CrystalButton value="Login" disabled={!buttonEnabled} onClick={onLogin} />
                </div>
            </div>
        </Notification>
        <Footer>
            <Link onClick={onShowTerminal}>Switch to terminal view</Link>
        </Footer>
    </ModalOverlay>
}