import classNames from "classnames";
import "./NamePlate.scss";

/**
 * Renders the name plate used on character summary displays
 */
export function NamePlate({ name, title, className, condition = "none" })
{
    return (<div className={className} styleName={classNames("name-plate", `health-${condition}`)}>
        <div styleName="fill" />
        <label title={title || name}>{name}</label>
    </div>);
}