import React from "react";
import { Header } from "../Header/Header";
import "./Window.scss";
import { Rnd } from "react-rnd";
import { ModalOverlay } from "../Modal/ModalOverlay";

const _minSize = {
    width: 100,
    height: 100
}

export function Window({ title, children, defaultPosition = { x: 0, y: 0 }, defaultSize = { width: 300, height: 400 }, minSize = { ..._minSize }, modal })
{
    const windowElements = (
        <React.Fragment>
            <Header className="drag-handle" framed={false} styleName="title">{title}</Header> 
            <div styleName="body">
                {children}
            </div>
        </React.Fragment>
    );

    if (!modal)
        return <Rnd style={{ display: "flex" }} styleName="window" dragHandleClassName="drag-handle" default={{ ...defaultSize, ...defaultPosition }} minWidth={minSize.width} minHeight={minSize.height} handle=".drag-handle">{windowElements}</Rnd >

    // Wrap the window in a modal overlay
    const windowContainerStyle =
    {
        ...defaultSize
    }

    return <ModalOverlay>
        <div styleName="window window-container" style={windowContainerStyle}>
            {windowElements}
        </div>
    </ModalOverlay>;
}