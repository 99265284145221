import { RoundFrame } from "../Frame/RoundFrame";
import "./Indicator.scss";
import classNames from "classnames";

export function Indicator({ size, color, className, styleName, ...props })
{
    const fillStyle = {
        backgroundColor: color || "transparent",
        opacity: !color || color === "none" ? 0 : 1
    }
    
    return (<RoundFrame size={size} className={className} styleName={classNames("indicator", size, styleName)} {...props}>
        <div styleName="fill" style={fillStyle} />
    </RoundFrame>);
}