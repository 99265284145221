import { forwardRef, useState } from "react";
import "./Textbox.scss";
import { Frame } from "../Frame/Frame";
import { useChainedCallback } from "../../hooks";

function TextboxRender({ className, ...props }, ref)
{
    const [hasFocus, setHasFocus] = useState(false);

    const onFocus = useChainedCallback(() =>
    {
        setHasFocus(true);
    }, props.onFocus);

    const onBlur = useChainedCallback(() =>
    {
        setHasFocus(false);
    }, props.onBlur);

    const inputProps = {
        autoFocus: false,
        autoCapitalize: "off",
        autoComplete: "off",
        type: "text",
        ...props,
        onBlur,
        onFocus
    }

    return <Frame className={className} styleName="textbox" active={hasFocus}>
        <input {...inputProps} ref={ref} />
    </Frame>
};

export const Textbox = forwardRef(TextboxRender);