import classNames from "classnames";
import { Avatar, Icon, Indicator, RoundFrame } from "../../components";
import { NamePlate } from "./NamePlate";
import "./Character.scss";
import { capitalize, getCharacterCondition, getQualityCategory } from "../../functions";
import { useCallback } from "react";
import pluralize from "pluralize";
import { Role } from "./Role";

/**
 * @typedef {object} CharacterModel
 * @property {string} id The unique identifier for the character
 * @property {string} name The display name for the character
 * @property {string} role The type of character ("npc", "player", "creator")
 * @property {number} healthPct The health percentage of the character
 * @property {string} avatar The image to use for this character
 * 
 */

/**
 * @typedef {object} UnitUIProps
 * @property {object} style The HTML style attributes to apply to the unit element
 * @property {number} group The set of characters represented by this instance
 */

function getActionsList(props)
{
    const { id, type, count, onAction } = props;

    const actions = [];
    if (type == "npc")
        actions.push({ actionType: "melee", command: `attack ${count > 1 ? pluralize(id) : id}` });

    actions.push({ actionType: "look", command: `look at ${id}` });

    if (!actions.length)
        return undefined;

    return (<div styleName="actions-list">
        {actions.map(({ command, actionType }, index) =>
        {
            const onClick = onAction && useCallback(() =>
            {
                onAction({ id, actionType, command });
            }, [onAction])
            return <Icon key={index} type={actionType} onClick={onClick} />
        })}
    </div>);
}

/**
 * Visual representation of an NPC
 * @param {CharacterModel & UnitUIProps} props
 */
export function Character(props)
{
    const { id, name, title, type, role, group, stats, style, styleName, avatar } = props;
    const count = (group && group.length) || 1;
    const namePlateStyle = {};

    if (type === "admin" || type === "creator")
        characterType = "immortal";

    const displayName = capitalize(name);
    const condition = getCharacterCondition(props);

    return (<div data-id={id} style={style} styleName={classNames("character", styleName)}>
        <NamePlate style={namePlateStyle} styleName="name-plate" name={displayName} title={title} condition={condition} />
        <Avatar styleName="avatar" size="small" url={avatar} />
        {<Role styleName="character-role" role={role} />}
        {count > 1 && <RoundFrame size="small" styleName="character-count"><label>{count}</label></RoundFrame>}
        {getActionsList(props)}
    </div>)
}