import { getQualityCategory } from "./getQualityCategory";

/**
 * Returns the condition of a character
 */
export function getCharacterCondition(character)
{
    if (!character
        || !character.stats)
        return undefined;

    const stats = character.stats;
    
    if (!stats || !stats.health)
        return undefined;

    const { health } = stats;
    return getQualityCategory(health.value / health.maxValue);
}