/**
 * The list of quality categories
 */
export class Categories
{
    /**
     * Less than 20%
     */
    static Critical = "critical";

    /**
     * Between than 20% and 39%
     */
    static Poor = "poor";

    /**
     * Between than 40% and 59%
     */
    static Moderate = "moderate";

    /**
     * Between than 60% and 79%
     */
    static Good = "good";

    /**
     * Over 79%
     */
    static High = "high";
}

/**
 * Returns a category for a specific percentage value
 */
export function getQualityCategory(percentage)
{
    if (percentage === undefined)
        return "none";

    if (percentage < .2)
        return Categories.Critical;
    if (percentage < .4)
        return Categories.Poor;
    if (percentage < .6)
        return Categories.Moderate;
    if (percentage < .8)
        return Categories.Good;

    return Categories.High;
}

/**
 * Return the standard color used to represent a specific percentage
 */
export function getQualityCategoryColor(percentage)
{
    if (percentage === undefined)
        return undefined;

    if (percentage < .2)
        return "rgb(255, 0, 0)";

    if (percentage < .4)
        return "rgb(255, 191, 0)";

    if (percentage < .6)
        return "rgb(255, 255, 0)";

    if (percentage < .8)
        return "rgb(0, 128, 0)";

    return "rgb(0, 255, 0)";
}