import classNames from "classnames";
import "./Avatar.scss";
import { DiamondFrame } from "../Frame/DiamondFrame";
import largeUnknownAvatar from "./images/large-unknown.png";
import mediumUnknownAvatar from "./images/medium-unknown.png";
import smallUnknownAvatar from "./images/small-unknown.png";

export function Avatar({ size, url, className, onClick })
{
    const classes = ["avatar"];
    let avatarImage = url;
    if (!avatarImage)
    {
        if (size === "large")
            avatarImage = largeUnknownAvatar;
        else if (size === "small")
            avatarImage = smallUnknownAvatar;
        else
            avatarImage = mediumUnknownAvatar;

        classes.push("unknown");
    }

    return (<DiamondFrame onClick={onClick} className={className} size={size || "medium"} styleName={classNames(...classes)}>
        <img src={avatarImage} />
    </DiamondFrame>);
}