import "./TerminalText.css";

const StandardAnsiForegroundColors =
    [
        "var(--ansi-black)",
        "var(--ansi-red)",
        "var(--ansi-green)",
        "var(--ansi-yellow)",
        "var(--ansi-blue)",
        "var(--ansi-magenta)",
        "var(--ansi-cyan)",
        "var(--ansi-white)",
        "var(--ansi-white)"                             // Default foreground
    ]

const StandardAnsiForegroundBrightColors =
    [
        "var(--ansi-bright-black)",
        "var(--ansi-bright-red)",
        "var(--ansi-bright-green)",
        "var(--ansi-bright-yellow)",
        "var(--ansi-bright-blue)",
        "var(--ansi-bright-magenta)",
        "var(--ansi-bright-cyan)",
        "var(--ansi-bright-white)",
        "var(--ansi-bright-white)"           // Default bright foreground
    ]

const StandardAnsiBackgroundColors =
    [
        "var(--ansi-black)",
        "var(--ansi-red)",
        "var(--ansi-green)",
        "var(--ansi-yellow)",
        "var(--ansi-blue)",
        "var(--ansi-magenta)",
        "var(--ansi-cyan)",
        "var(--ansi-white)",
        "var(--ansi-black)"                             // Default background
    ]

const StandardAnsiBackgroundBrightColors = StandardAnsiBackgroundColors;


/**
 * Returns the foreground color for this rendition
 */
function getForegroundColor(rendition)
{
    const { color, bold } = rendition;

    if (color >= 30 && color <= 38)
    {
        if (bold)
            return StandardAnsiForegroundBrightColors[color - 30];
        return StandardAnsiForegroundColors[color - 30];
    }

    if (color >= 60 && color <= 68)
        return StandardAnsiForegroundBrightColors[color - 60];

}

export function TerminalText({ text, rendition })
{
    const style = {}

    if (rendition)
    {
        style.color = getForegroundColor(rendition);
        style.bold = rendition.bold ? "bold" : undefined;
        style.textDecoration = rendition.underline ? "underline" : undefined;
        style.fontStyle = rendition.italics ? "italic" : undefined;
    }

    return (<span style={{...style }}>{text}</span>);
}