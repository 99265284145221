import { Frame } from "../Frame/Frame";
import "./Button.scss";

export function Button(props)
{
    const buttonProps =
    {
        ...props,
        type: "button"
    };

    return (<Frame>
        <input styleName="button" {...buttonProps} />
    </Frame>);
}