import { useCallback, useEffect, useRef, useState } from "react";
import { useChainedCallback } from "../../hooks";
import "./TerminalInput.scss";
import { Button, Textbox } from "../../components";

export function TerminalInput({ onSend, maxHistory = 100, ...props })
{
    const [text, setText] = useState("");
    const [history, setHistory] = useState({ browsing: false, entries: [], index: 0, filter: "" });
    const inputRef = useRef();

    const onInputCallback = useChainedCallback((event) =>
    {
        setText(event.target.value);
        setHistory({ ...history, browsing: false });
    }, props.onInput);

    const onSendCallback = useCallback((event) =>
    {
        const sendText = history.browsing ? history.entries[history.index] : text;

        if (!sendText.length)
            return;

        if (onSend && typeof (onSend) === "function")
            onSend(sendText, inputRef.current.type === "password");

        if (props.type !== "password")
        {
            const entries = history.entries.slice();
            entries.push(sendText);
            while (entries.length > maxHistory)
                entries.shift();

            setHistory({ ...history, entries, browsing: false, filter: "", index: 0 });
        }

        setText("");
    });

    const startHistoryBrowsing = (event, direction) =>
    {
        if (!history.entries.length)
            return;

        console.log("browsing history");
        setHistory((state) =>
        {
            return browseHistory({ ...state, browsing: true, filter: event.target.value }, direction == 1 ? 0 : history.entries.length - 1, direction);
        });
    }

    const browseHistory = (history, startIndex, direction) =>
    {
        const entries = history.entries;
        const filter = history.filter.length ? new RegExp(`^${history.filter}`, "gi") : undefined;

        if (!entries.length)
            return history;

        // Find the next matching entry in the history
        let index = startIndex;

        let match = false;
        while (!match)
        {
            if (index < 0)
            {
                if (filter && !filter.test(entries[index]))
                {
                    index = -1;
                    break;
                }

                // We reached the start, stop progressing
                index = 0;
                break;
            }
            else if (index > entries.length - 1)
            {
                // We reached the end, no matches
                index = -1;
                break;
            }

            if (!filter)
                break;

            match = filter.test(entries[index]);
            if (match)
                break;

            // Move to the next entry.
            index = index + direction;
        }

        return { ...history, browsing: index !== -1, index }
    }

    const onKeyDown = useChainedCallback((event) =>
    {
        switch (event.code)
        {
            case "Enter":
                return onSendCallback(event);

            case "ArrowUp":
                if (!history.browsing)
                    startHistoryBrowsing(event, -1);
                else
                    setHistory(browseHistory(history, history.index - 1, -1));

                event.preventDefault();
                event.stopPropagation();

                return;
            case "ArrowDown":
                if (!history.browsing)
                    break;

                setHistory(browseHistory(history, history.index + 1, 1));

                event.preventDefault();
                event.stopPropagation();
                return;
            default:
        }
    }, props.onKeyDown);

    useEffect(() =>
    {
        if (!history.browsing)
            return;

        const textbox = inputRef.current;
        if (!textbox)
            return;

        const historyFilter = history.filter;
        textbox.focus();
        textbox.setSelectionRange(historyFilter.length, textbox.value.length, "none");

    }, [inputRef, history])

    const browsing = history.browsing && history.index !== -1;
    const style = {};
    if (browsing)
    {
        style.fontStyle = "italic";
        style.color = "rgba(255, 255, 255, .5)";
    }

    const textboxSettings = {
        type: "text",
        ...props,
        value: browsing ? history.entries[history.index] : text,
        style,
        onInput: onInputCallback,
        onKeyDown: onKeyDown
    };

    return (<div styleName="terminal-input">
        <Textbox styleName="input-textbox" {...textboxSettings} ref={inputRef} placeholder="Enter a command, or use the Up arrow to browse the history." />
        <Button value="Send" onClick={onSendCallback} />
    </div>);
}