import { MUDClient } from "../../src/services/MUDClient";
import collectedData from "./web-socket-test-data";

export class MockMUDClient extends MUDClient
{
    #paused = false;

    #timeoutHandle = 0;

    receivedBuffer = [...collectedData];

    get connected() { return true; }

    pause()
    {
        const timeoutHandle = this.#timeoutHandle;
        if (!timeoutHandle)
            return;

        clearTimeout(timeoutHandle);
        this.#timeoutHandle = 0;
        this.#paused = true;
    }

    resume()
    {
        this.#paused = false;
        this.#triggerNextMessage();
    }

    #triggerNextMessage()
    {
        let nextTimeout = (Math.random() * 500) + 500;
        const timeoutHandle = setTimeout(() => { this.sendNextMessage(); }, nextTimeout);
        this.#timeoutHandle = timeoutHandle;
    }

    onGMCPMessage(command, data)
    {
        switch (command.toLowerCase())
        {
            case "char.login.default":
                this.pause();
                break;
        }

        super.onGMCPMessage(command, data);
    }

    sendNextMessage()
    {
        const data = this.receivedBuffer.shift();
        this.onMessage(Uint8Array.from(data));

        if (!this.#paused)
            this.#triggerNextMessage();
    }

    connect({ url })
    {
        setTimeout(() => { this.emit("connected") });
        this.#triggerNextMessage();
    }

    send(data)
    {

    }
}